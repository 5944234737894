@import './../../assets/styles/shared/shared';

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);

  @media ($md) {
    display: none;
  }
}
