@import './../../assets/styles/shared/shared';

.master {
  margin: 0 0 8px;
  padding: 0;
  font-family: ROSerifBold;
  color: $color-grey;
}

.h1 {
  composes: master;
  margin: 0 0 24px;
  font-size: 3.4rem;
  line-height: 4rem;
  color: $color-green;

  @media ($lg) {
    font-size: 6rem;
    line-height: 7.5rem;
  }
}

.h2 {
  composes: master;
  font-size: 2.4rem;
  line-height: 3.2rem;

  @media ($md) {
    margin-bottom: 40px;
    font-size: 3.4rem;
    line-height: 4rem;
  }
}

.h3 {
  composes: master;
  font-size: 2rem;
  line-height: 2.8rem;

  @media ($md) {
    margin-bottom: 32px;
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
}

.h4 {
  composes: master;
  font-size: 1.8rem;
  line-height: 2.6rem;

  @media ($md) {
    font-size: 2rem;
    line-height: 2.6rem;
  }
}

.h5 {
  composes: h2;

  @media ($md) {
    margin-bottom: 8px;
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
}

.large {
  composes: h2;
  margin-bottom: 16px;
  font-size: 3.4rem;
  line-height: 4.4rem;

  @media ($md) {
    margin-bottom: 48px;
    font-size: 4.8rem;
    line-height: 6.2rem;
  }
}

.article {
  composes: h3;

  @media ($md) {
    margin-bottom: 8px;
  }
}

.colorGrey {
  color: $color-grey;
}

.colorGreen {
  color: $color-green;
}

.colorPink {
  color: $color-pink;
}

.colorRed {
  color: $color-red;
}
