@import './../../assets/styles/shared/shared';

/* ===== GENERAL ===== */
.sticky {
  @media ($lg) {
    position: sticky;
    top: 108px;
    align-self: flex-start;
    transition: all 0.2s ease-out;

    :global(.showHeader) & {
      top: 208px;
    }

    &:before,
    &:after {
      content: '';
      display: table;
    }
  }
}

.background {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% * (7 / 12));
    height: 100%;
    background-color: $color-pink;
    z-index: -1;
  }
}

.textLink {
  margin: 0 !important;
  text-decoration: underline;
}

.link {
  display: inline-block;
  margin-left: 16px;
  margin-right: 0 !important;
  transition: color 0.2s ease-out;

  &:after {
    content: '\e901';
    display: inline;
    position: relative;
    top: -2px;
    margin: 0 0 0 8px;
    font-family: 'PZIcons';
    line-height: 1;
    transition: margin 0.2s ease-out;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:visited {
    color: $color-darkred;
  }

  &:hover {
    color: $color-green;

    &:after {
      margin-left: 10px;
    }
  }
}

.noBullets li {
  list-style: none !important;
}

/* ===== HOME ===== */
.title {
  h1 {
    margin: 0;
  }
}

.introCopy {
  @media ($md) {
    order: 0;
  }
}

.about {
  @media ($md) {
    padding-top: 40px;
  }
}

.aboutImage {
  width: 100%;
  height: 100%;
  margin-bottom: 16px;

  @media ($md) {
    display: none;
  }
}

/* ====== HELP ===== */
.helpTitle {
  h1 {
    max-width: none;

    @media (max-width: 380px), (min-width: 1160px) and (max-width: 1260px) {
      max-width: 75%;
    }
  }
}

/* ==== CHOICES ==== */
.accordionQuote {
  @media ($md) {
    width: 82% !important;
  }
}
