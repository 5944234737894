@import '../../assets/styles/shared/shared';

.list > div {
  margin-bottom: 16px;

  /* display: flex;
  flex-direction: column;
  list-style: none; */

  /* @media ($s) {
    flex-direction: row;
  }

  li {
    margin: 0 0 16px;

    &:last-child {
      margin: 0;
    }

    @media ($sm) {
      margin-right: 16px;
    }
  } */
}
