@import './../../assets/styles/shared/shared';

.wrapper {
  margin: 8px 0 40px;

  @media ($md) {
    margin: 0;
  }
}

.player {
  margin: 0 0 16px;
}

.button {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  margin: 0 0 16px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 68px;
    height: 69px;
    background: url('../../assets/icons/play.svg') no-repeat center center;
    transform: translate(-50%, -50%);
    transition: transform 0.2s ease-in-out;
  }

  &:hover:before {
    transform: translate(-50%, -50%) scale(1.1);
  }

  img {
    width: 100%;
  }
}

:global(.video-js .vjs-big-play-button) {
  top: 50%;
  left: 50%;
  width: 68px;
  height: 69px;
  background: transparent url('../../assets/icons/play.svg') no-repeat center
    center;
  border: none;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }

  & :global(.vjs-icon-placeholder) {
    display: none;
  }

  @media ($md) {
    display: none;
  }
}

:global(.video-js:hover .vjs-big-play-button) {
  background-color: transparent;
}

.quote {
  margin: 0;

  p {
    margin-bottom: 16px;
  }
}
