@import './../../assets/styles/shared/shared';

.wrapper {
  margin: 48px 0 0;
  padding: 32px 56px;
  background-color: $color-green;

  @media ($md) {
    margin: 80px 0 0;
  }
}

.list {
  margin-bottom: 32px;

  li {
    margin: 0 0 8px;
  }

  a {
    font-size: 1.8rem;
    line-height: 2.7rem;
    color: $color-white;

    &:hover {
      text-decoration: underline;
    }
  }
}

.about {
  max-width:600px;
  color: $color-white;
}
