@import './../../assets/styles/shared/shared';

.wrapper {
  flex-direction: column;
  width: calc(100% + 32px);
  margin: 0 -16px;

  @media ($md) {
    flex-direction: row;
    width: auto;
    margin: 0 -8px 80px -8px;

    & > div {
      width: auto;
      padding-left: 8px;
      padding-right: 8px;
    }

    & > div:nth-child(odd) {
      order: -1;
      background-color: $color-pink;
    }
  }

  @media ($xl) {
    &:before {
      content: '';
      position: absolute;
      top: auto;
      left: 0;
      width: 72%;
      height: 287px;
      background-color: $color-pink;
      z-index: -1;
    }
  }

  & > div {
    width: calc(100% - 16px);
    padding: 0;
  }

  & > div:nth-child(odd) {
    // margin-left: -16px;
  }

  & > div:nth-child(3),
  & > div:nth-child(4) {
    // margin-right: -16px;
    align-self: flex-end;

    @media ($md) {
      align-self: auto;
    }
  }
}
