@import '../../assets/styles/shared/shared';

.wrapper {
  width: 100%;
  margin: 0 24px 24px 0;
  padding: 16px;
  background-color: $color-green;

  &,
  p {
    color: $color-white;
  }
}

.wrapperWithIcon {
  composes: wrapper;

  &:before {
    content: '';
    display: block;
    width: 42px;
    height: 30px;
    margin: 0 0 16px;
    background: url('../../assets/icons/quotes.svg') no-repeat top left;
  }
}

.wrapperWithButton {
  composes: wrapper;
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 56px;
}

.wrapperCarousel {
  display: flex !important;
  flex-direction: column;
  height: 100%;

  & .cite {
    margin-top: auto;
  }
}

.wrapperWithButton.wrapperCarousel {
  height: calc(100% - 32px);
}

.title {
  margin: 0;
  font-family: ROSansBold;
  font-weight: bold;
}

.quote {
  margin: 0;
  padding: 0;

  p {
    margin: 0 0 24px;
  }
}

.cite {
  display: block;
  position: relative;
  padding: 0 0 0 24px;
  font-family: ROSansRegular;
  font-size: 1.4rem;
  font-style: normal;
  line-height: 1.7rem;

  &:before {
    content: '—';
    position: absolute;
    top: 0;
    left: 0;
  }

  strong {
    margin: 0 8px 0 0;
    font-family: ROSansBold;
  }
}

.button {
  position: absolute;
  left: 16px;
  bottom: -32px;
  margin: 0;
}

.fixedWidth {
  width: calc(1240px * (3 / 12));
}
