@import-normalize; /* bring in normalize.css styles */

/* Shared */
@import 'shared/shared';

/* Fonts */
@import 'fonts';

/* packages */
@import 'accordion';
@import 'slick';
@import 'slick-theme';
@import 'bootstrap/bootstrap-grid';
// @import 'bootstrap/bootstrap';
@import 'video.js/src/css/video-js.scss';

// Debug
* {
  // border: 1px solid red;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  font-weight: 100;
  scroll-padding-top: 60px; /* works in Chrome 73, should be moved to html when this bug is fixed */

  @media ($md) {
    scroll-padding-top: 100px; /* works in Chrome 73, should be moved to html when this bug is fixed */
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

[data-whatinput='mouse'] :focus,
[data-whatinput='touch'] :focus {
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: ROSansRegular;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $color-grey;

  @media ($md) {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}

a {
  text-decoration: none;
  color: $color-grey;
}

a:focus,
button:focus,
.accordion__button {
  outline-color: $color-green;
}

*::selection {
  background-color: $color-green;
  color: $white;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }

  ul {
    margin-top: 24px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0 0 16px 0;
}

strong {
  font-family: ROSansBold;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

.container-fluid {
  max-width: 1240px;
  padding: 0;
  // padding-left: $grid-gutter-width;
  // padding-right: $grid-gutter-width;
}

.container {
  max-width: 100%;
}

[class*='col'] {
  position: static;
}

.bg-pink {
  &:before {
    background-color: $color-pink;
  }
}

.bg-right {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: -8px;
    width: 50vw;
    height: 100%;
    z-index: -1;
  }
}
