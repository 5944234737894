@import '../../assets/styles/shared/shared';

.wrapper {
  align-self: flex-start;
  margin-bottom: 16px;
  padding: 16px;
  background-color: $color-pink;
}

.title {
  margin-bottom: 16px;
  font-family: ROSansBold;
  font-size: 1.4rem;
  font-weight: bold;
}

.copy {
  margin: 0;
}
