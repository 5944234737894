@import '../../assets/styles/shared/shared';

.cta {
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  margin: 0;
  padding: 0 0 0 24px;
  background: $color-pink url('../../assets/icons/arrow-long.svg') no-repeat;
  background-position: right 24px center;
  background-size: 16px 10px;
  font-family: ROSansRegular;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: $color-grey;
  text-decoration: none;
  transition: all 0.3s ease-out;

  &:hover {
    background-color: darken($color-pink, 3%);
    background-position: right 20px center;
  }

  @media ($sm) {
    display: block;
    height: 100%;
    padding: 16px 48px 16px 16px;
    background-position: right 16px bottom 20px;
    font-size: 2rem;
    line-height: 2.6rem;

    &:hover {
      background-position: right 12px bottom 20px;
    }
  }

  @media ($md) {
    display: flex;
    height: 56px;
    padding: 4px 0 4px 24px;
    background-position: right 24px center;
    font-size: 2rem;
    line-height: 2.6rem;

    &:hover {
      background-position: right 20px center;
    }
  }

  @media ($lg) {
    display: block;
    height: 100%;
    padding: 16px 48px 16px 16px;
    background-position: right 16px bottom 20px;
    font-size: 2rem;
    line-height: 2.6rem;

    &:hover {
      background-position: right 12px bottom 20px;
    }
  }
}

.icon {
  display: inline;
  width: 37px;
  height: 30px;
  margin: 0 8px 0 0;

  @media ($sm) {
    display: block;
    margin: 0 0 8px;
  }

  @media ($md) {
    display: inline;
    margin: 0 8px 0 0;
  }

  @media ($lg) {
    display: block;
    margin: 0 0 8px;
  }
}
