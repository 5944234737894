// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: eot woff2 woff ttf svg
) {
  $src: null;

  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_')
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype'
  );

  @each $ext in $exts {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + '.' + $extmod)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

// =============================================================================
// Hovers
// =============================================================================

@mixin hover-underline($type: fade, $dir: null, $weight: 2px, $color: #000) {
  @if $dir == null {
    @if $type == fade {
      $dir: 'top';
    } @else if $type == slide {
      $dir: 'center';
    }
  }

  position: relative;
  display: inline-block;
  text-decoration: none;
  &::after {
    position: absolute;
    left: 0;
    content: '';
    width: 100%;
    height: $weight;
    background: $color;
    @if $type == fade {
      transition: 0.3s;
      opacity: 0;
      visibility: hidden;
      @if $dir == bottom {
        bottom: $weight * -4;
      } @else {
        bottom: $weight;
      }
    } @else if $type == slide {
      bottom: $weight * -2;
      transform: scale(0, 1);
      transition: transform 0.3s;
      @if $dir == left-right or $dir == right-in-left {
        transform-origin: left top;
      } @else if $dir == right-left or $dir == left-in-right {
        transform-origin: right top;
      } @else {
        transform-origin: center top;
      }
    }
  }
  &:hover::after {
    @if $type == fade {
      bottom: $weight * -2;
      opacity: 1;
      visibility: visible;
    } @else if $type == slide {
      @if $dir == left-in-right {
        transform-origin: left top;
      } @else if $dir == right-in-left {
        transform-origin: right top;
      }
      transform: scale(1, 1);
    }
  }
}
