@import '../../assets/styles/shared/shared';

.wrapper {
  position: relative;
  margin: 0;

  @media ($md) {
    margin-bottom: 120px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 20%;
    height: 100%;
    background-color: lighten($color-pink, 5%);
    z-index: -1;

    @media ($md) {
      top: -40px;
      height: calc(100% + 80px);
    }

    @media (min-width: 1520px) {
      width: 35%;
    }
  }

  &:before {
    left: -16px;
  }

  &:after {
    right: -16px;
    background-color: lighten($color-green, 60%);
  }
}
