@import '../../assets/styles/shared/shared';

.wrapper {
  margin: 0 0 24px 0;
  background-color: $color-white;
  border: 1px solid $color-grey;
}

.title {
  padding: 16px;
  border-bottom: 1px solid $color-grey;
}

.content {
  padding: 16px;

  a {
    // margin-left: 16px;
  }
}
