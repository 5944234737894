@import '../../assets/styles/shared/shared';

.quote {
  padding: 24px 16px;
  background-color: $color-pink;

  @media ($xl) {
    background-color: transparent;
  }

  h3 {
    display: flex;
    margin: 0 0 24px 0;
  }

  .icon {
    float: left;
    height: 24px;
    margin: 2px 8px 0 0;
  }

  blockquote {
    margin: 0;
    padding: 0;
  }
}

.copyWrapper {
  margin: 0 0 32px 0;
  padding: 0 16px;

  @media ($md) {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.copy {
  width: 100%; // IE11 fix
  margin: 16px 0 24px;
}
