.link {
  display: block;
  position: relative;
  padding: 0 0 0 32px;

  &:before {
    content: '\e901';
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    width: 24px;
    height: 16px;
    transform: rotate(90deg);
    font-family: 'PZIcons';
    line-height: 1;
    transition: all 0.2s ease-out;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:hover {
    &:before {
      transform: rotate(90deg) translateX(2px);
    }
  }

  &.active {
    &:before {
      top: 0;
      transform: none;
    }

    &:hover {
      &:before {
        transform: translateX(2px);
      }
    }
  }
}
