@import '../../assets/styles/shared/shared';

.wrapper {
  margin-bottom: 48px;

  a {
    margin-right: 24px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    margin-left: 16px;

    li {
      list-style: disc;
      margin: 0 0 24px;
    }
  }

  @media ($md) {
    p {
      margin-bottom: 32px;
    }
  }

  @media ($md) {
    margin-bottom: 80px;
  }
}

.wrapperIntro {
  margin-bottom: 32px;

  @media ($md) {
    margin-bottom: 60px;
  }
}

.wrapperWithButton {
  composes: wrapper;
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 56px;
}

.colorPink {
  /* display: flex;
  flex-direction: column;
  align-items: baseline; */
  padding: 24px;
  background-color: $color-pink;

  @media ($md) {
    padding: 40px 24px 32px;
  }

  p {
    margin-bottom: 24px;
  }
}

.left {
  margin-left: -16px;
}

.right {
  margin-right: -16px;
}

.button {
  position: absolute;
  left: 16px;
  bottom: -32px;
}
