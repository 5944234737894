@import './../../assets/styles/shared/shared';

.wrapper {
  @media ($md) {
    display: flex;
    justify-content: space-between;
  }

  > li,
  .item {
    padding: 24px 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 8px solid $color-white;
    border-image: url('../../assets/images/dots-white.svg') 33% round;

    @media ($md) {
      position: relative;
      padding: 0;
      border: none;
    }

    > a,
    > button {
      font-family: ROSerifBold;
      font-size: 2.2rem;
      line-height: 3.2rem;
      color: $color-white;

      &.active {
        color: $color-pink;
        text-decoration: underline;
      }

      @media ($md) {
        font-family: ROSansRegular;
        font-size: 1.6rem;
        line-height: 1.8rem;
        color: $color-grey;
        @include hover-underline('fade', 'bottom', 1px, $color-grey);

        &.active {
          font-family: ROSansBold;
          font-weight: bold;
          color: $color-grey;
          text-decoration: none;
        }
      }
    }
  }
}

.submenuWrapper {
  position: absolute;
  top: 24px;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 16px 32px 16px;
  background-color: $color-green;
  transform: translateX(100%);
  transition: transform 0.2s ease-out;

  // .active + &,
  .wrapperSubmenuActive & {
    transform: translateX(0);
  }
}

.submenu {
  li {
    margin-bottom: 48px;
  }

  a {
    display: block;
    margin-left: 24px;
    font-family: ROSansRegular;
    font-weight: normal;
    font-size: 2rem;
    color: $color-white;

    &:after {
      content: '\e900';
      display: inline-block;
      position: relative;
      top: -2px;
      margin-left: 8px;
      font-family: 'PZIcons';
      line-height: 1;
      transition: all 0.2s ease-out;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &:hover {
      text-decoration: underline;

      &:after {
        margin-left: 10px;
      }
    }

    &.active {
      font-family: ROSansBold;
      text-decoration: underline;
    }
  }
}

.hideOnDesktop {
  composes: item;

  @media ($md) {
    display: none;
  }

  a {
    margin-left: 0;

    &.active {
      font-family: ROSerifBold;
    }
  }
}

.last {
  padding: 0 !important;
  border: none !important;
}

@media ($md) {
  .submenuWrapper {
    display: block;
    position: relative;
    top: 0;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: transparent;
    transform: translateX(0);
    transition: all 0.2s ease-out;

    li:hover &,
    a:hover + &,
    &:hover {
      visibility: visible;
      opacity: 1;
    }
  }

  .submenu {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 16px 24px;
    background-color: $color-darkpink;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      border-width: 10px 10px 0 10px;
      border-style: solid;
      border-color: $color-darkpink transparent transparent transparent;
      z-index: 10;
      opacity: 1;
      transform: translateX(-50%) translatey(-100%) rotate(-180deg);
    }

    li {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      margin-left: 0;
      font-size: 1.6rem;
      color: $color-grey;

      &.active {
        font-family: ROSansBold;
        font-weight: bold;
      }
    }
  }
}

.buttonBack {
  cursor: pointer;

  &:before {
    content: '\e900';
    display: inline-block;
    position: relative;
    top: 10px;
    margin-left: 8px;
    font-family: 'PZIcons';
    line-height: 1;
    transform: rotate(180deg);
    transition: all 0.2s ease-out;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
