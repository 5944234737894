@import './../../assets/styles/shared/shared';

.hidden {
  display: none;
}

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
}

.video {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 80vw;
  height: 45vw;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 101;
}

.btnClose {
  cursor: pointer;
  position: absolute;
  top: -48px;
  right: 0;
  width: 32px;
  height: 32px;
  z-index: 102;
  transition: transform 0.2s ease-out;

  &:hover {
    transform: rotate(90deg);
  }
}
