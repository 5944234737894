@import './../../assets/styles/shared/shared';

.logo {
  display: block;
  margin: 0;
  padding: 0;
  font-family: ROSerifBold;
  font-size: 2rem;
  color: $color-green;
  text-align: right;

  @media ($md) {
    text-align: left;
  }
}
