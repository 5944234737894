@import './../../assets/styles/shared/shared';

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 60px;
  padding: 12px 16px 12px 16px;
  background-color: white;
  z-index: 90;
  transition: all 0.3s ease-out;

  @media ($md) {
    padding: 32px 16px 40px 16px;
    max-height: 100px;

    :global(.minimizeHeader) & {
      padding: 16px 16px 24px 16px;
    }
  }
}

/*.hidden {
  max-height: 0;
  padding: 0;
  opacity: 0;

  @media ($md) {
    // transform: translateY(-100px);
  }
}*/

.row {
  align-items: baseline;
}
