@import './../../assets/styles/shared/shared';

.list {
  @media ($md) {
    margin-left: 24px;
  }

  li {
    margin: 0 0 16px 0;
  }
}
