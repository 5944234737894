@import './../../assets/styles/shared/shared';

.wrapper {
  position: absolute;
  top: auto;
  left: 0;
  width: 100%;
  height: 56px;
  margin: 0;
  background: $color-darkpink;
  z-index: 80;
  transition: transform 0.3s ease-out;
}

.hidden {
  // visibility: hidden;
  // background-color: $color-white;
  transform: translateY(-70px);
}

.list {
  display: flex;
  justify-content: space-around;
}

.item {
  text-align: center;
  font-size: 1.6rem;

  a:hover {
    text-decoration: underline;
  }
}

.current {
  text-decoration: underline;
}
