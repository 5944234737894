@import './../../assets/styles/shared/shared';

.main {
  margin-top: 60px;
  padding: 0 16px;

  @media ($md) {
    margin-top: 100px;
  }
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 60px;
  transform: translateZ(0);
  transition: transform 0.2s ease-out;
  z-index: 90;

  @media ($md) {
    min-height: 100px;
  }
}

.hideNav {
  transform: translate3d(0, -100%, 0);
}
