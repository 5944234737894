@import '../../assets/styles/shared/shared';

.separator {
  margin: 32px 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 8px solid $color-green;
  border-image: url('../../assets/images/dots.svg') 33% round;

  @media ($md) {
    display: none;
  }
}
