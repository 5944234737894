@import '../../assets/styles/shared/shared';

.wrapper {
  margin-bottom: 24px;
}

.link {
  display: inline-block;
  margin-right: 0 !important;
  transition: color 0.2s ease-out;

  &:after {
    content: '\e901';
    display: inline;
    position: relative;
    top: -2px;
    margin: 0 0 0 8px;
    font-family: 'PZIcons';
    line-height: 1;
    transition: margin 0.2s ease-out;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:visited {
    color: $color-darkred;
  }

  &:hover {
    color: $color-green;

    &:after {
      margin-left: 10px;
    }
  }
}

.indent {
  margin-left: 16px;
}
