@import '../../assets/styles/shared/shared';

.copy {
  font-family: ROSansRegular;
  font-size: 1.8rem;
  line-height: 2.7rem;
  color: $color-grey;

  @media ($md) {
    margin-bottom: 40px;
    font-size: 2rem;
    line-height: 3rem;
  }
}
