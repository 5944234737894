@import './../../assets/styles/shared/shared';

.wrapper {
  position: relative;
  margin-right: -16px;

  @media ($md) {
    width: 60vw;
  }
}

.arrow {
  cursor: pointer;
  display: none;

  @media ($md) {
    display: block;
    position: absolute;
    top: 50%;
    left: -40px;
    padding: 8px 16px;
    transform: translateY(-100%);
    transition: all 0.2s ease-out;

    &:hover {
      transform: translate(-3px, -100%);
    }
  }
}

.slide {
  height: auto;
}
