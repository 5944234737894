@import './../../assets/styles/shared/shared';

.wrapper {
  margin: 8px 0 40px;

  @media ($md) {
    margin: 0;
  }

  img {
    width: 100%;
  }
}

.link {
  position: relative;
  display: inline-block;
  margin: 0 0 16px !important;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 88px;
    height: 71px;
    background: url('../../assets/icons/article.svg') no-repeat center center;
    transform: translate(-50%, -50%);
    transition: transform 0.2s ease-in-out;
  }

  &:hover:before {
    transform: translate(-50%, -50%) scale(1.1);
  }
}

.quote {
  margin: 0;

  p {
    margin-bottom: 16px;
  }
}
