@import '../../assets/styles/shared/shared';

.wrapper {
  align-self: flex-end;
  margin-bottom: 16px;
  padding: 16px;
  background-color: $color-green;
  color: $color-white;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  margin-bottom: 16px;
  font-family: ROSansBold;
  font-size: 1.4rem;
  font-weight: bold;
}

.quote {
  color: $color-pink;
  font-size: 3.2rem;
}

.quotes {
  &:before,
  &:after {
    content: '“';
    font-size: 3.2rem;
    color: $color-pink;
    vertical-align: -20%;
    padding: 0 4px 0 0;
  }

  &:after {
    content: '”';
    padding: 0 0 0 4px;
  }
}

.link {
  color: $color-white;

  &:hover {
    color: $color-white;
  }

  &:visited {
    color: $color-white;
  }
}
