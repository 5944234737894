// @import 'typography';

.accordion__item {
  margin: 0 0 24px;
  padding: 24px 16px;
  background-color: $color-pink;
}

.accordion__heading {
  margin: 0;
}

.accordion__button {
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;

  &:hover {
    .title:before {
      transform: translateY(3px);
    }
  }

  .suptitle {
    display: block;
    font-family: ROSansRegular;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .title {
    display: block;
    position: relative;
    padding-right: 52px;
    font-family: ROSerifBold;
    font-size: 1.8rem;
    line-height: 2.6rem;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      right: 8px;
      height: 17px;
      width: 17px;
      background: url('./../icons/arrow-down.svg') no-repeat;
      transition: transform 0.2s ease-out;
    }
  }

  .intro {
    margin: 8px 0 0 0;
    padding-right: 24px;
    font-family: ROSansRegular;
    font-size: 1.6rem;
    line-height: 2.4rem;

    @media ($md) {
      padding-right: 48px;
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
  }
}

.accordion__button[aria-expanded='true'] .title::before,
.accordion__button[aria-selected='true'] .title::before {
  transform: rotate(180deg);
}

.accordion__panel {
  margin: 16px 0 0;
  padding: 0 8px 0 0;
  animation: fadein 0.25s ease-in;

  @media ($md) {
    padding: 0 48px 0 0;
  }

  ul,
  ol {
    margin-bottom: 32px;
  }
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
