@import './../../assets/styles/shared/shared';

.button {
  display: inline-block;
  padding: 16px;
  border: 1px solid $color-grey;
  background-color: $color-white;
  font-family: ROSansRegular;
  font-size: 1.4rem;
  line-height: 1.6rem;
  transition: all 0.2s ease-out;

  &:after {
    content: '\e900';
    display: inline-block;
    position: relative;
    top: -1px;
    margin-left: 4px;
    font-family: 'PZIcons';
    // font-size: 1rem;
    line-height: 1;
    transition: all 0.2s ease-out;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:hover {
    padding-right: 24px;

    &:after {
      content: '\e901';
      margin-left: 8px;
    }

    color: $color-white;
    background-color: $color-grey;
  }
}

.colorGrey {
  color: $color-grey;

  &:hover {
    background-color: $color-grey;
    color: $color-white;
  }
}

.colorGreen {
  border-color: $color-green;
  color: $color-green;

  &:hover {
    background-color: $color-green;
    color: $color-white;
  }
}

.colorQuote {
  composes: colorGrey;

  &:hover {
    background-color: $color-pink;
    color: $color-grey;
  }
}
