@import './../../assets/styles/shared/shared';

.wrapper {
  position: relative;

  @media ($md) {
    min-height: 560px;
    margin-bottom: 80px;
  }
}

.content {
  position: static;
  display: flex;
  flex-direction: column;
}
