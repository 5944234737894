@include font-face(
  ROSerifBold,
  './../fonts/ROserifwebbold',
  null,
  null,
  eot ttf woff
);

@include font-face(
  ROSansRegular,
  './../fonts/ROsanswebtextregular',
  null,
  null,
  eot ttf woff
);

@include font-face(
  ROSansBold,
  './../fonts/ROsanswebtextbold',
  null,
  null,
  eot ttf woff
);

/*@font-face {
  font-family: 'PZIcons';
  src: url('./../fonts/PZIcons.eot');
  src: url('./../fonts/PZIcons.eot?#iefix') format('embedded-opentype'),
    url('./../fonts/PZIcons.woff') format('woff'),
    url('./../fonts/PZIcons.ttf') format('truetype'),
    url('./../fonts/PZIcons.svg#PZIcons') format('svg');
  font-weight: normal;
  font-style: normal;
}*/

@font-face {
  font-family: 'PZIcons';
  src: url('./../fonts/icomoon.eot?qv3oof');
  src: url('./../fonts/icomoon.eot?qv3oof#iefix') format('embedded-opentype'),
    url('./../fonts/icomoon.ttf?qv3oof') format('truetype'),
    url('./../fonts/icomoon.woff?qv3oof') format('woff'),
    url('./../fonts/icomoon.svg?qv3oof#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
