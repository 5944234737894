@import '../../assets/styles/shared/shared';

.wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
  overflow: hidden;
  z-index: 200;
  padding: 32px 16px;
  background-color: $color-green;
  transition: transform 0.3s ease-out;

  @media ($md) {
    display: none;
  }
}

.closeButton {
  position: absolute;
  top: 24px;
  right: 16px;
  width: 14px;
  height: 15px;
}

.open {
  transform: translateX(0);
}

.closed {
  transform: translateX(-100%);
}
