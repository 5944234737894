@import '../../assets/styles/shared/shared';

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 16px 0 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: -8px;
    width: 180px;
    height: 100%;
    background-color: $color-pink;
    z-index: -1;
  }

  @media ($md) {
    margin-bottom: 24px;

    &:before {
      display: none;
    }
  }
}

.wrapperWithFixedBackgroundHeight {
  composes: wrapper;

  &:before {
    height: 240px;

    @media ($md) {
      height: 100%;
    }
  }
}

.quote {
  @media ($xl) {
    position: absolute;
    bottom: 0;
    right: calc(100% * (4 / 12) - (4 * 8px));
    width: calc(100% * (3 / 12)) !important;
    z-index: 1;
  }
}

.visual {
  width: 100%;
  padding: 0;

  img {
    width: 100%;
  }

  @media ($md) {
    position: absolute;
    top: 24px;
    right: calc(100% * (1 / 12) + 8px);

    &:before {
      content: '';
      position: absolute;
      top: -24px;
      right: calc(-100% * (3 / 12));
      width: 75%;
      height: 88%;
      background-color: $color-pink;
      z-index: -1;
    }

    &:after {
      position: absolute;
      content: '';
      bottom: -40px;
      height: 40px;
      width: 1px;
    }
  }
}

.top {
  order: 0;
}

.bottom {
  order: 1;
  margin-top: 24px;
}
